<!-- 推品清单 - 详情 -->
<template>
  <div class="car" id="car">
    <h3 class="pageTitle">{{ pageTitle }}</h3>
    <div class="table">
      <ul class="table__top">
        <li class="table__top--check">
          <el-checkbox v-model="allchecked" @change="allcheckedChange"></el-checkbox>
        </li>
        <li class="table__top--checkText">全选</li>
        <li class="table__top--name">商品信息</li>
        <li class="table__top--level">规格</li>
        <li class="table__top--price">批发价</li>
        <li class="table__top--quantity">采购量</li>
        <li class="table__top--allPrice">小计</li>
      </ul>
      <!-- 水羊自有仓商品 -->
      <div v-if="shoppingCartActivityVOS.length > 0">
        <div class="table__title">
          <el-checkbox v-model="normalChecked" @change="normalCheckedAllChange"></el-checkbox>
          <span class="table__title--title">自有仓商品</span>
        </div>
        <ul class="table__content table-border">
          <li
            v-for="i in shoppingCartActivityVOS"
            :key="i.id"
            class="table__content--detail-item"
            :class="i.status !== '1' ? 'table__content--itemInvalid' : ''"
          >
            <div class="table__content--check">
              <el-checkbox
                v-model="i.isChecked"
                class="table__content--checkBox"
                v-if="i.status === '1'"
                @change="normalCheckedChange"
              ></el-checkbox>
              <span v-else class="table__content--invalid">失效</span>
            </div>
            <div class="table__content--name">
              <oss-image :src="i.imgUrl" class="table__content--nameImg" />
              <p class="table__content--nameName">{{ i.commodityName }}</p>
              <span class="table__content--rebate" v-if="i.creditBack === '1'">返利</span>
              <span class="table__content--rebate" v-if="i.seckillRuleCartVO">限时</span>
            </div>
            <div class="table__content--level">
              <p>
                {{ i.firstLevel ? i.firstLevel : '' }}{{ i.secondLevel | judge
                }}{{ i.threeLevel | judge }}
              </p>
              <p>规格标识:{{ i.skuId }}</p>
            </div>
            <div class="table__content--price">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ i.price }}
              </p>
            </div>
            <div class="table__content--quantity">
              <el-input-number
                v-if="i.status === '1'"
                :min="i.miniOrderQuantity"
                v-model="i.quantity"
                @change="
                  (currentValue, oldValue) => {
                    quantityChange(currentValue, oldValue, i.id, 'normal');
                  }
                "
                class="table__content--quantityInput"
              ></el-input-number>
              <p v-if="i.status === '1'" class="table__content--quantityInputNum">
                起订量{{ i.miniOrderQuantity }}件起
              </p>
              <span v-else class="table__content--inputInvalid">{{ i.quantity }}</span>
            </div>
            <div class="table__content--allPrice">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ (i.price * i.quantity).toFixed(2) }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 水羊合作仓商品 -->
      <div v-if="internationShoppingCartActivityVOS.length > 0">
        <div class="table__title">
          <el-checkbox v-model="globalChecked" @change="globalCheckedAllChange"></el-checkbox>
          <span class="table__title--title">合作仓商品</span>
        </div>
        <ul class="table__content table-border">
          <li
            v-for="i in internationShoppingCartActivityVOS"
            :key="i.id"
            class="table__content--detail-item"
            :class="i.status !== '1' ? 'table__content--itemInvalid' : ''"
          >
            <div class="table__content--check">
              <el-checkbox
                v-model="i.isChecked"
                class="table__content--checkBox"
                v-if="i.status === '1'"
                @change="globalCheckedChange"
              ></el-checkbox>
              <span v-else class="table__content--invalid">失效</span>
            </div>
            <div class="table__content--name">
              <oss-image :src="i.imgUrl" class="table__content--nameImg" />
              <p class="table__content--nameName">{{ i.commodityName }}</p>
              <span class="table__content--rebate" v-if="i.creditBack === '1'">返利</span>
              <span class="table__content--rebate" v-if="i.seckillRuleCartVO">限时</span>
            </div>
            <div class="table__content--level">
              <p>
                {{ i.firstLevel ? i.firstLevel : '' }}{{ i.secondLevel | judge
                }}{{ i.threeLevel | judge }}
              </p>
              <p>规格标识:{{ i.skuId }}</p>
            </div>
            <div class="table__content--price">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ i.price }}
              </p>
            </div>
            <div class="table__content--quantity">
              <el-input-number
                v-if="i.status === '1'"
                :min="i.miniOrderQuantity"
                v-model="i.quantity"
                @change="
                  (currentValue, oldValue) => {
                    quantityChange(currentValue, oldValue, i.id, 'global');
                  }
                "
                class="table__content--quantityInput"
              ></el-input-number>
              <p v-if="i.status === '1'" class="table__content--quantityInputNum">
                起订量{{ i.miniOrderQuantity }}件起
              </p>
              <span v-else class="table__content--inputInvalid">{{ i.quantity }}</span>
            </div>
            <div class="table__content--allPrice">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ (i.price * i.quantity).toFixed(2) }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      style="text-align: center; width: 1100px"
      v-if="shoppingCartActivityVOS.length === 0 && internationShoppingCartActivityVOS.length === 0"
    >
      暂无商品
    </div>
    <!-- 合计 -->
    <div id="combined-position"></div>
    <div
      class="combined-div"
      :style="positionFlag ? styles.fixed : styles.relative"
      :class="{ 'bottom-fixed': positionFlag }"
    >
      <div class="combined">
        <div class="fl">
          <el-checkbox v-model="allchecked" @change="allcheckedChange">全选</el-checkbox>
        </div>
        <div class="fr">
          <p>
            已选商品
            <span>{{ count }}</span>
            种 <span>{{ total }}</span> 件
            <span>￥{{ allPrice }}</span>
          </p>
          <el-button v-if="!isYearlyRecommendType" @click="addToCart" type="primary"
            >加入采购车</el-button
          >
          <el-button class="settlement" @click="settlement" type="primary">去结算</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="合并结算提示"
      :visible.sync="dialogFormVisible"
      width="470px"
      class="dialog"
      :close-on-click-modal="false"
    >
      <p class="dialog__title">以下商品不支持同时结算，请分开结算！</p>
      <p class="dialog__item">
        <el-radio v-model="dialogValue" label="1"
          >自有仓商品(共选中{{ normalCount }}种商品,{{ normalTotal }}件)</el-radio
        >
      </p>
      <p class="dialog__item">
        <el-radio v-model="dialogValue" label="0"
          >合作仓商品(共选中{{ globalCount }}种商品,{{ globalTotal }}件)</el-radio
        >
      </p>
      <div slot="footer" class="dialog__footer">
        <el-button @click="dialogFormVisible = false" class="dialog__footer--btn">取 消</el-button>
        <el-button type="primary" @click="saveType">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { removeByIds } from '@/api/car/car';
import { listDetailForPc, addRecommendPlanToCart } from '@/api/recommended-list/index';
import { mapActions } from 'vuex';
import eventReporter from '@/utils/event-reporter';
export default {
  name: 'car',
  data() {
    return {
      id: this.$route.params.id,
      pageTitle: '',
      shoppingCartActivityVOS: [],
      internationShoppingCartActivityVOS: [],
      normalChecked: false,
      globalChecked: false,
      allchecked: false,
      domLeft: 0,
      styles: {
        fixed: {
          position: 'fixed',
          left: this.domLeft,
        },
        relative: {
          position: 'relative',
        },
      },
      positionFlag: false,
      dialogFormVisible: false,
      dialogValue: '1',
      beforeFrom: {}, // 上级页面对象 埋点用
      recommendType: '', // 清单类型
      distributorPartyRelationId: '', // 分销商主数据渠道ID
    };
  },
  computed: {
    // 是否为年框商品清单
    isYearlyRecommendType() {
      return this.recommendType === 'YEARLY';
    },
    // 自有仓商品种类数
    normalCount() {
      return this.calculationCount(this.shoppingCartActivityVOS);
    },
    // 自有仓商品件数
    normalTotal() {
      return this.calculationTotal(this.shoppingCartActivityVOS);
    },
    // 合作仓商品种类数
    globalCount() {
      return this.calculationCount(this.internationShoppingCartActivityVOS);
    },
    // 合作仓商品件数
    globalTotal() {
      return this.calculationTotal(this.internationShoppingCartActivityVOS);
    },
    // 商品种类数
    count() {
      const goodsArr = [
        ...this.shoppingCartActivityVOS,
        ...this.internationShoppingCartActivityVOS,
      ];
      return this.calculationCount(goodsArr);
    },
    // 商品件数
    total() {
      const goodsArr = [
        ...this.shoppingCartActivityVOS,
        ...this.internationShoppingCartActivityVOS,
      ];
      return this.calculationTotal(goodsArr);
    },
    // 商品总额
    allPrice() {
      const goodsArr = [
        ...this.shoppingCartActivityVOS,
        ...this.internationShoppingCartActivityVOS,
      ];
      let price = 0;
      goodsArr.forEach(i => {
        if (i.status === '1' && i.isChecked) {
          price += i.quantity * i.price;
        }
      });
      return price.toFixed(2);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.beforeFrom = from;
    });
  },
  created() {},
  async mounted() {
    await this.getList();
    this.getCountStatisticsHot();
    this.domLeft = document.getElementById('car').getBoundingClientRect().left;
    window.addEventListener('scroll', this.handleScroll, true);
    this.allcheckedChange(true);
    this.allcheckedFn();
    this.$nextTick(() => {
      eventReporter.trackRecommendPlanDetail(this.$route, this.beforeFrom, {
        recommendPlanId: this.id,
        recommendPlanName: this.pageTitle,
        source: this.beforeFrom.path === '/' ? 'PUSH_CODE' : 'RECOMMEND_LIST',
        commodityCount: this.count,
        commodityQuantity: this.total,
      });
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    // 获取购物车列表
    async getList() {
      const { data } = await listDetailForPc({ recommendId: this.id });
      this.pageTitle = data.recommendName;
      this.recommendType = data.recommendType || '';
      this.distributorPartyRelationId = data.distributorPartyRelationId || '';
      const res = data;
      res.shoppingCartActivityVOS &&
        res.shoppingCartActivityVOS.forEach(i => {
          if (i.status === '1') {
            i.isChecked = false;
          }
        });
      res.internationShoppingCartActivityVOS &&
        res.internationShoppingCartActivityVOS.forEach(i => {
          if (i.status === '1') {
            i.isChecked = false;
          }
        });
      this.shoppingCartActivityVOS = res.shoppingCartActivityVOS || [];
      this.internationShoppingCartActivityVOS = res.internationShoppingCartActivityVOS || [];
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    // 水羊自有仓商品选择
    normalCheckedChange() {
      let flag = true;
      for (const i of this.shoppingCartActivityVOS) {
        if (i.status === '1' && !i.isChecked) {
          flag = false;
          break;
        }
      }
      this.normalChecked = flag;
      this.allcheckedFn();
    },
    normalCheckedAllChange(val) {
      this.shoppingCartActivityVOS.forEach(i => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
      this.allcheckedFn();
    },
    // 水羊合作仓商品选择
    globalCheckedChange() {
      let flag = true;
      for (const i of this.internationShoppingCartActivityVOS) {
        if (i.status === '1' && !i.isChecked) {
          flag = false;
          break;
        }
      }
      this.globalChecked = flag;
      this.allcheckedFn();
    },
    globalCheckedAllChange(val) {
      this.internationShoppingCartActivityVOS.forEach(i => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
      this.allcheckedFn();
    },
    // 全选
    allcheckedChange(val) {
      this.normalChecked = val;
      this.globalChecked = val;
      this.shoppingCartActivityVOS.forEach(i => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
      this.internationShoppingCartActivityVOS.forEach(i => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
    },
    allcheckedFn() {
      if (this.globalChecked && this.normalChecked) {
        this.allchecked = true;
      } else {
        this.allchecked = false;
      }
    },
    // 单个删除商品
    handleDelete(val, index, type) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          const ids = [val.id];
          removeByIds(ids).then(response => {
            if (type === 'normal') {
              this.shoppingCartActivityVOS.splice(index, 1);
              this.$message({
                type: 'success',
                message: '删除成功！',
              });
            } else {
              this.internationShoppingCartActivityVOS.splice(index, 1);
              this.$message({
                type: 'success',
                message: '删除成功！',
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 批量删除商品
    allDel() {
      const goodsArr = [
        ...this.shoppingCartActivityVOS,
        ...this.internationShoppingCartActivityVOS,
      ];
      const ids = [];
      goodsArr.forEach(i => {
        if (i.status === '1' && i.isChecked) {
          ids.push(i.id);
        }
      });
      if (ids.length > 0) {
        this.$confirm('确认要批量删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            removeByIds(ids).then(response => {
              this.normalChecked = false;
              this.globalChecked = false;
              this.allchecked = false;
              this.getList();
              this.getCountStatisticsHot();
              this.$message({
                type: 'success',
                message: '删除成功！',
              });
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择需要删除商品',
        });
      }
    },
    // 修改商品数量
    quantityChange(currentValue, oldValue, id, type) {
      // const data = {
      //   newQuantity: currentValue,
      //   id: id,
      // };
      // updateCommodityQuantity(data)
      //   .then((res) => {
      //     this.UPDATE_SET_CARNUMBER(currentValue - oldValue);
      //   })
      //   .catch(() => {
      //     if (type === 'normal') {
      //       this.shoppingCartActivityVOS.forEach((item) => {
      //         if (item.id === id) {
      //           item.quantity = oldValue;
      //         }
      //       });
      //     } else {
      //       this.internationShoppingCartActivityVOS.forEach((item) => {
      //         if (item.id === id) {
      //           item.quantity = oldValue;
      //         }
      //       });
      //     }
      // });
    },
    ...mapActions(['getCountStatisticsHot']),
    // 清空失效商品
    delDisableShop() {
      this.$confirm('确认要清空失效商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          const goodsArr = [
            ...this.shoppingCartActivityVOS,
            ...this.internationShoppingCartActivityVOS,
          ];
          const ids = [];
          goodsArr.forEach(i => {
            if (i.status !== '1') {
              ids.push(i.id);
            }
          });
          if (ids.length < 1) {
            this.$message({
              type: 'warning',
              message: '无失效商品',
            });
          } else {
            removeByIds(ids).then(response => {
              this.normalChecked = false;
              this.globalChecked = false;
              this.allchecked = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '已清空',
              });
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 结算
    settlement() {
      let normalNum = false;
      let globalNum = false;
      for (const i of this.shoppingCartActivityVOS) {
        if (i.status === '1' && i.isChecked) {
          normalNum = true;
          break;
        }
      }
      for (const i of this.internationShoppingCartActivityVOS) {
        if (i.status === '1' && i.isChecked) {
          globalNum = true;
          break;
        }
      }

      if (normalNum && globalNum) {
        this.dialogFormVisible = true;
      }
      if (!normalNum && !globalNum) {
        this.$message({
          type: 'warning',
          message: '请先选择结算商品',
        });
      }
      if (normalNum && !globalNum) {
        this.settlementNormal('shopping');
      }
      if (!normalNum && globalNum) {
        this.settlementNormal('internationShopping');
      }
    },
    // 加入购物车
    addToCart() {
      const arr = [];
      let fag = false;
      const goodsList = [
        ...this.shoppingCartActivityVOS,
        ...this.internationShoppingCartActivityVOS,
      ];
      for (const goods of goodsList) {
        if (goods.isChecked) {
          arr.push({
            commodityId: goods.commodityId,
            quantity: goods.quantity || 1,
            skuId: goods.skuId,
          });
          if (goods.isHidden === '1') {
            fag = true;
            break;
          }
        }
      }
      if (arr.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择要加购的商品',
        });
        return;
      }
      if (fag) {
        this.$message({
          type: 'warning',
          message: '加购商品中存在【隐藏商品】，【隐藏商品】无法加入购物车',
        });
        return;
      }

      addRecommendPlanToCart({
        recommendPlanId: this.id,
        createShoppingCartCmds: arr,
      }).then(res => {
        this.$message({
          type: 'success',
          message: '成功加入购物车',
        });
      });
    },
    // 弹窗结算
    saveType() {
      if (this.dialogValue === '1') {
        this.settlementNormal('shopping');
      } else {
        this.settlementNormal('internationShopping');
      }
    },
    // 商品类型
    settlementNormal(type) {
      const data = [];
      this[`${type}CartActivityVOS`].forEach(i => {
        if (i.status === '1' && i.isChecked) {
          i.allPrice = (i.price * i.quantity).toFixed(2);
          data.push(i);
        }
      });
      sessionStorage.setItem('orderDetail', JSON.stringify(data));
      this.$router.push({
        path: '/syoung/checkout/list',
        query: {
          type,
          goodsType: 'recommended',
          recommendId: this.id,
          brandType: type === 'shopping' ? 'NORMAL' : 'INTERNATION',
          recommendType: this.recommendType,
          distributorPartyRelationId: this.distributorPartyRelationId,
        },
      });
    },
    // 结算按钮样式
    handleScroll() {
      const divTop = document.getElementById('combined-position').getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (divTop + 70 > windowHeight) {
        this.positionFlag = true;
      } else {
        this.positionFlag = false;
      }
    },
    // 计算商品种类数
    calculationCount(list) {
      let num = 0;
      list.forEach(i => {
        if (i.status === '1' && i.isChecked) {
          num += 1;
        }
      });
      return num;
    },
    // 计算商品总件数
    calculationTotal(list) {
      let num = 0;
      list.forEach(i => {
        if (i.status === '1' && i.isChecked) {
          num += i.quantity;
        }
      });
      return num;
    },
  },
  filters: {
    judge(val) {
      if (val) {
        return ` / ${val}`;
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import './styles';
.pageTitle {
  margin-bottom: 10px;
  font-size: 16px;
}
.bottom-fixed {
  line-height: 60px;
  background: white;
  box-shadow: 0px -2px 7.7px 0px #0000001a;
}
.table-border {
  border-top: 3px solid #b1b1b1;
  border-left: 1px solid #e6e8eb;
  border-right: 1px solid #e6e8eb;
  margin-bottom: 10px;
}
</style>
